@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@font-face {
    font-family: "CocoSharp"; 
    font-style: normal; 
    font-weight: normal;
    src: url("../fonts/cocosharp/CocoSharp-Bold.otf") format("truetype");
  }

body{
    font-family: CocoSharp;
    color: white;
    background-color: black;
}
